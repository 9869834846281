import axios from "axios";
import info from "../components/info-dialog";
import router from "../router";
import UserService from "./UserService";
import EventBus from "./EventBus";

export default class Axios {
  constructor(fullLoader = true, headers = {},silent = false) {
    this._headers = headers;
    this.axios = this._createInstance();
    this.silent = silent;
    this._registerRequestInterceptor(fullLoader);
    this._registerResponseInterceptor(fullLoader,silent);
  }

  _createInstance() {
    const token = localStorage.getItem("accessToken");
    let baseURL = "/update-api"
    if (process.env.NODE_ENV !== "production"){
      baseURL = "http://127.0.0.1:3090"
    }else {
      const url = localStorage.getItem("url");
      if (url) {
        const strings = url.split("/api");
        baseURL = strings[0]+"/update-api"
      }
    }
    if (token) {
      this._headers.Authorization = "Bearer " + token;
    }
    return axios.create({
      baseURL,
      timeout: 300000,
      headers: this._headers,
      maxContentLength: 100000000,
      maxBodyLength: 1000000000,
    });
  }

  _registerRequestInterceptor(fullLoader) {
    if (fullLoader) {
      console.log(fullLoader);
    }

    this.axios.interceptors.request.use(async (config) => {
      //  console.log(config);
      // aqui colocar a validação para fazer requisições só caso o LocalStorage.getItem('server') estiver como ONLINE;
      // ele é definido no arquivo SocketIO.js
      // if (config.url + JSON.stringify(config.data) !== lastRequest) {
      //   lastRequest = config.url + JSON.stringify(config.data);
      // }
      return config;
    });
  }

  _registerResponseInterceptor(fullLoader,silent) {
    this.axios.interceptors.response.use(
      (response) => {
        if (
          response.headers["x-token"] != null &&
          response.headers["x-token"] !== ""
        ) {
          localStorage.setItem("accessToken", response.headers["x-token"]);
        }
        console.log(response);
        return response;
      },
      async (error) => {
        if (silent) return Promise.resolve(error);
        //  console.log(error);
        if (error.message === "Network Error") {
          info.open(
            "Parece que o servidor se encontra offline, tente novamente daqui a pouco.",
            () => {
              EventBus.emit("Axios", "closeDialog");
              info.close();
            }
          );
          return Promise.reject(error);
        }
        if (error.code === "ECONNABORTED") {
          info.open(
            "Parece que o servidor está demorando um pouco para responder. Por favor, tente novamente.",
            () => {
              EventBus.emit("Axios", "closeDialog");
              info.close();
            }
          );
          return Promise.reject(error);
        }
        if (error.response.status < 500) {
          if (
            error.response.data.message &&
            error.response.data.message ===
              "Este cartão não foi cadastrado no sistema. Tente cadastrar este número ou informar outro"
          )
            return Promise.reject(error.response.data.message);
          console.log(error.response.data);
          if (error.response.data.code > 600) {
            return Promise.reject(error.response.data.message);
          }
          let message = error.response.data.error
              ? error.response.data.error
              : "Ocorreu um erro"
          if (error.response.data.status)message = error.response.data.status
          info.open(message,
            () => {
              EventBus.emit("Axios", "closeDialog");
              info.close();
            }
          );
          return Promise.reject(error);
        }

        if (
          error.response.data.message ===
          "A sessão expirou ou é inválida, faça o login novamente"
        ) {
          info.open(
            "A sessão expirou ou é inválida, faça o login novamente",
            () => {
              EventBus.emit("Axios", "closeDialog");
              UserService.logout(() => router.push("/login"));
              info.close();
            }
          );
          return Promise.reject(error);
        }
        info.open(
          !error.response || !error.response.data.message
            ? "Ocorreu um erro não tratado no servidor."
            : error.response.data.message,
          () => {
            EventBus.emit("Axios", "ErrorConfirmation");
            info.close();
          }
        );
        return Promise.reject(error);
      }
    );
  }
}
