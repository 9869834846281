<template>
  <v-dialog v-model="visible" width="500" persistent max-width="350">
    <v-card>
      <v-card-title class="headline">Confirmação</v-card-title>
      <v-card-text>
        <p class="body-1">
          {{ message }}
        </p>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="_close">{{ cancelButton }}</v-btn>
        <v-btn color="accent darken-1" text @click="_confirm">{{
          confirmButton
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ConfirmDialogComponent",
  props: {
    cancelButton: {
      type: String,
      default: "Cancelar",
    },
    confirmButton: {
      type: String,
      default: "Confirmar",
    },
    visible: {
      type: Boolean,
      default: true,
    },
    message: {
      type: String,
      default: "Tem certeza?",
    },
    confirm: {
      typ: Function,
      default: () => {},
    },
    close: {
      typ: Function,
      default: () => {},
    },
  },
  methods: {
    handleClose() {
      this.visible = false;
      this.$destroy();
      removeElement(this.$el);
    },

    _close() {
      if (this.close) this.close();
      this.handleClose();
    },

    async _confirm() {
      if (this.confirm) await this.confirm();
      this.handleClose();
    },
  },
  beforeMount() {
    document.querySelector("#app").appendChild(this.$el);
  },
  mounted() {},
};

function removeElement(el) {
  if (typeof el.remove !== "undefined") {
    el.remove();
  } else {
    el.parentNode.removeChild(el);
  }
}
</script>
