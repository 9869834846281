import InfoDialogComponent from "./InfoDialog";
import Vue from "vue";
import Vuetify from "vuetify/lib";
import "vuetify/dist/vuetify.min.css";
import vuetifySettings from "../../plugins/vuetify";

let component;
let lastMessage;
export default {
  open(message, confirm) {
    let propsData = {
      visible: true,
      message: message,
      confirm: confirm,
    };
    if (message === lastMessage) return;

    const el = document.createElement("div");
    Vue.use(Vuetify);
    const cd = Vue.extend(InfoDialogComponent);
    component = new cd({
      el,
      vuetify: new Vuetify(vuetifySettings),
      propsData,
    });
  },

  close() {
    component.close();
  },
};
