import ConfirmDialogComponent from "./ConfirmDialog";
import Vue from "vue";
import Vuetify from "vuetify/lib";
import "vuetify/dist/vuetify.min.css";
import vuetifySettings from "../../plugins/vuetify";

export default {
  open(message, onConfirm, onClose, options = {}) {
    return new Promise((resolve) => {
      let propsData = {
        visible: true,
        message: message,
        cancelButton: options.cancelButtonText,
        confirmButton: options.confirmButtonText,
        confirm: () => {
          if (onConfirm) {
            resolve(onConfirm());
          } else {
            resolve();
          }
        },
        close: () => {
          if (onClose) {
            resolve(onClose());
          } else {
            resolve();
          }
        },
      };
      Vue.use(Vuetify);
      const cd = Vue.extend(ConfirmDialogComponent);
      this.component = new cd({
        el: document.createElement("div"),
        vuetify: new Vuetify(vuetifySettings),
        propsData,
      });
    });
  },

  close() {
    this.component.close();
  },
};
