import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import info from "./components/info-dialog";
import confirmDialog from "./components/confirm-dialog";
import router from "./router";
import 'material-design-icons-iconfont/dist/material-design-icons.css'

Vue.config.productionTip = false
Vue.prototype.$info = info;
Vue.prototype.$confirm = async (
    message,
    onConfirm,
    onClose,
    options = { confirmButtonText: "Confirmar", cancelButtonText: "Cancelar" }
) => {
  return await confirmDialog.open(message, onConfirm, onClose, options);
};
new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
