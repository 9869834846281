import Vue from "vue";

const EventBus = new Vue();
const eventsRegister = [];

//Classe responsável por normalizar os eventos globais da aplicação.
export default {
  async on(eventName, callback) {
    EventBus.$on(eventName, (args) => {
      if (args.length === 1) {
        args = args[0];
      }
      callback(args);
    });
  },

  async emit(component, method, ...args) {
    const filter = eventsRegister.filter(
      (item) => item.call === `${component}.${method}`
    )[0];
    const newEvent = {
      call: `${component}.${method}`,
      args: args,
    };
    if (filter) {
      eventsRegister.splice(eventsRegister.indexOf(filter), 1, newEvent);
    } else {
      eventsRegister.push(newEvent);
    }
    EventBus.$emit(`${component}.${method}`, args);
  },
};
