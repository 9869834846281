export default class RouteService {
  /**
   * @param {string|number} name
   * @returns Route
   */
  static get(name = "") {
    if (!name || name === "")
      throw Error(
        "O nome da rota é requirido para o método RouteService.get(name:string)"
      );
    let result = null;
    RouteService.getAll().forEach((route) => {
      if (route.children) {
        route.children.forEach((child) => {
          if (child.name === name) {
            result = child;
          }
        });
      }
      if (result) return;

      if (route.name === name) {
        result = route;
      }
    });
    // const route = RouteService.getAll().filter(route => {
    //   return (
    //     route.name === name ||
    //     (route.children && route.children.filter(child => child.name === name))
    //   );
    // });
    //
    // if (route.length > 0) {
    //   if (!route[0].children) return route[0];
    //
    //   const child = route[0].children.filter(child => child.name === name);
    //   return child[0];
    // }
    return result;
  }

  /**
   * @returns []Route
   */
  static getAll() {
    return [
      {
        path: "/login",
        name: "login",
        component: () => import("../components/Login.vue"),
      },
      {
        path: "/landing",
        name: "landing",
        component: () => import("../views/Landing.vue"),
      },
      {
        path: "/index",
        name: "index",
        redirect: "/index",
        component: () => import("../views/Index.vue"),
        children: [
          {
            path: "/index",
            name: "Index",
            component: () => import("../views/Dashboard.vue"),
          },{
            path: "/list-updates",
            name: "list-updates",
            component: () => import("../views/ListUpdates.vue"),
          },{
            path: "/list-logs",
            name: "list-logs",
            component: () => import("../views/ListLogs.vue"),
          },{
            path: "/dashboard",
            name: "dashboard",
            component: () => import("../views/Dashboard.vue"),
          },
        ],
      },
      {
        path: "/",
        component: () => import("../views/Landing.vue"),
      },
    ];
  }
}
