import Vue from "vue";
import Router from "vue-router";
import UserService from "./services/UserService";
import RouteService from "./services/RouteService";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: RouteService.getAll(),
});

router.beforeEach((to, from, next) => {
  console.log(to);
  if (to.fullPath !== "/login" && to.fullPath !== "/") {
    if (!UserService.getToken()) {
      next("/login");
    }
  }
  //if (to.fullPath === "/login") {
    //if (UserService.getToken()) {
      //next("/index");
    //}
  //}
  next();
});

export default router;
