import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: "#636e72", // #E53935
                secondary: "#0984e3", // #FFCDD2
                accent: "#0984e3", // #3F51B5
            },
        },
    },
});
