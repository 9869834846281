import Axios from "./Axios";

export default class UserService extends Axios {
  async login(credentials) {
    if (!credentials.login) throw Error("Por favor, informe o login");
    if (!credentials.password) throw Error("Por favor, informe a senha");
    await this.axios.post("/login", credentials);
  }

  static getToken() {
    return localStorage.getItem("accessToken");
  }

  static logout(cb) {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("signedUser");
    localStorage.removeItem("signedEmployee");
    localStorage.removeItem("pdv");
    localStorage.removeItem("settings");
    cb();
  }

}
