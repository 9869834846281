<template>
  <v-dialog v-model="visible" width="500" persistent max-width="350">
    <v-card>
      <v-card-title class="headline unselectable">Informação</v-card-title>
      <v-card-text>
        <p class="body-1 unselectable">
          {{ message }}
        </p>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="accent darken-1"
          text
          @click="confirm_"
          ref="infoDialogOkBtn"
          >Ok</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "InfoDialogComponent",
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
    message: {
      type: String,
      default: "",
    },
    confirm: {
      typ: Function,
      default: () => {},
    },
  },
  methods: {
    close() {
      this.visible = false;
      this.$destroy();
      removeElement(this.$el);
    },
    async confirm_() {
      this.close();
      if (this.confirm) await this.confirm();
    },
  },
  beforeMount() {
    document.querySelector("#content") &&
      document.querySelector("#content").appendChild(this.$el);
  },
  mounted() {
    setTimeout(() => {
      this.$refs.infoDialogOkBtn.$el.focus();
    }, 1);
  },
};

function removeElement(el) {
  if (typeof el.remove !== "undefined") {
    el.remove();
  } else {
    el.parentNode.removeChild(el);
  }
}
</script>
